<template>
  <Fragment>
    <fieldset :id="field.id" class="info__group">
      <select
        :id="`${field.id}-select-dependent`"
        v-model="value[field.id]"
        v-validate="field.validate + '|is_not:undefined'"
        :class="`info__group-select ${field.id}__select`"
        :name="field.id"
      >
        <option value="undefined" disabled>{{ field.label }}</option>
        <option
          v-for="item in field.options"
          :key="item.value"
          :value="item.value"
        >
          {{ item.text }}
        </option>
      </select>
      <span
        class="info__error"
        :class="{ 'info__error--on': errors.has(field.id) }"
      >{{ errors.first(field.id) }}</span>
    </fieldset>

    <DependentSelect
      v-if="field.nextSelect && nextSelectDynamic"
      :key="nextSelectDynamic.id + String(i + 1)"
      v-model="value"
      :top-value="value[field.id]"
      :field="nextSelectDynamic"
      :i="i + 1"
    />
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
// interface DependentSelectOption {
//   text: string;
//   value: string;
//   nextSelectOptions: DependentSelectOption[];
// }
// interface DependentSelect {
//   id: string;
//   type: "select-dependent";
//   label: string;
//   validate: string; // "required" or vee-validate rule
//   nextSelect: DependentSelect;
// }
// interface DependentSelectProps {
//   id: string;
//   type: "select-dependent";
//   label: string;
//   options: DependentSelectOption[];
//   validate: string; // "required" or vee-validate rule
//   nextSelect: DependentSelect;
// }

// TODO: fix validation

export default {
  name: 'dependent-select',
  components: {
    Fragment
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    i: {
      type: Number,
      required: true
    },
    topValue: {
      type: String || Number || Boolean,
      required: false
    }
  },
  data () {
    return {};
  },
  computed: {
    nextSelectDynamic () {
      return this.value[this.field.id]
        ? {
          ...this.field.nextSelect,
          options: this.field.options.find(
            option => option.value === this.value[this.field.id]
          ).nextSelectOptions
        }
        : null;
    }
  },
  watch: {
    topValue: {
      handler () {
        this.value[this.field.id] = undefined;
      }
    }
  },
  methods: {}
};
</script>

<style lang="less"></style>
